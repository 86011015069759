import React from 'react'
import { NavLink } from 'react-router-dom'
import BaseComponent from './BaseComponent'
import Scroll from 'react-scroll'

let scroller = Scroll.scroller

export default class Header extends BaseComponent {

  constructor(props) {

    super(props)
    this.state = {nav: false, sticky: false}
    this._bind('toggleNav', 'closeNav', 'scrollTo')
  }

  componentDidMount() {
    document.addEventListener('scroll', (e) => {
      if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
            this.setState({ sticky: true })
        } else {
            this.setState({ sticky: false })
        }
      })
  }

  scrollTo(el) {
    let offset = 70

    if (this.props.screen.width > 1020) {
      offset = 80
    }

    if (this.props.screen.width > 1280) {
      offset = 95
    }

    if (this.props.screen.width > 1590) {
      offset = 115
    }

    if (this.props.screen.width > 1910) {
      offset = 155
    }

    scroller.scrollTo(el, {
      duration: 1200,
      delay: 100,
      smooth: "easeInOutQuad",
      offset: -offset
    })
  }

  toggleNav() {
    if ( this.state.nav ) {
      this.setState({ nav: false })
    } else {
      this.setState({ nav: true })
    }
  }

  closeNav(e) {
    this.setState({ nav: false })
  }

  render() {

    return (
      <header className={ this.state.sticky ? "sticky" : "" }>
        <div className="container-big">
          <NavLink to="/" onClick={ () => { this.closeNav(); this.scrollTo('head') } }><img className="logo" src="/assets/img/logo.png" alt="Logo"/></NavLink>
          <div className={ this.state.nav ? "burger open" : "burger" } onClick={ this.toggleNav }><span></span></div>
          <nav className={ this.state.nav ? "open" : "" }>
            <ul>
              <li><NavLink to="" exact className="nav-item" activeClassName="active" onClick={ () => { this.closeNav(); this.scrollTo('head') } }>Strona główna</NavLink></li>
              <li><NavLink to="/oferta" exact className="nav-item" activeClassName="active" onClick={ () => { this.closeNav(); this.scrollTo('products') } }>Oferta</NavLink></li>
              <li><NavLink to="/uslugi" exact className="nav-item" activeClassName="active" onClick={ () => { this.closeNav(); this.scrollTo('offer') } }>Usługi</NavLink></li>
              <li><NavLink to="/finansowanie" exact className="nav-item" activeClassName="active" onClick={ () => { this.closeNav(); this.scrollTo('financing') } }>Finansowanie</NavLink></li>
              <li><NavLink to="/kontakt" exact className="nav-item" activeClassName="active" onClick={ () => { this.closeNav(); this.scrollTo('contact') } }>Kontakt</NavLink></li>
            </ul>
          </nav>
        </div>
      </header>
    )
  }
}