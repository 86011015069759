import 'react-app-polyfill/ie9'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import MainView from './app/MainView'

export default class Main {

	preloadJSON( dataURL) {

	    fetch(dataURL)
	    .then((response) => {
	        return response.json()
	    })
	    .then((result) => {
	      this.data = result
	      this.ready()
	    }, (error) => {
	      console.log(error)
	    })
	}

	init() {
		this.preloadJSON("/data/data.json")
	}

	ready() {
		this.render()
	}

	render() {
		const mainContainer = document.getElementById('app')

		ReactDOM.render(
			<Router>
				<MainView 
					data={ this.data }/>
			</Router>
		, mainContainer)
	}
}

const app = new Main()
app.init()