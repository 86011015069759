import React from 'react'
import BaseComponent from './BaseComponent'

export default class Footer extends BaseComponent {

  componentDidMount() {
    if (window.gtag) {
      window.gtag('config', 'AW-732274715/DF28CJi5makBEJvAlt0C',{
        'phone_conversion_number': '(+48) 22 843 86 70'
      });
    }
  }

  render() {

    return (
      <footer>
      	<div className="container">
      		<ul className="contact-data">
      			<li>
      				<h4>Dane<br/>korespondencyjne</h4>
      				<p>Bankomat Sp. z o.o. sp.k.<br/>ul. Potocka 27<br/>01-619 Warszawa</p>
      			</li>
      			<li>
      				<h4>Kontakt<br/>telefoniczny</h4>
      				<p><a href="tel:(+48) 22 843 86 70">(+48) 22 843 86 70</a></p>
      			</li>
      		</ul>
      		<img className="footer-logo" src="/assets/img/logo-white.png" alt="Bankomat logo"/>
      		<p className="copyrights">Bankomat 2019r. Wszystkie prawa zastrzeżone</p>
      	</div>
      </footer>
    )
  }
}