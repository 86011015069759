import React from 'react'
import BaseComponent from './components/BaseComponent'
import { withRouter, Switch, Route } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Header from './components/Header'
import ErrorPage from './components/ErrorPage'
import HomePage from './components/HomePage'
import '../style.css'

class MainView extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { screen : { width: window.innerWidth, height: window.innerHeight }}
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this))
    if (typeof window.ga === 'function') {
      window.ga('set', 'page', this.props.location.pathname + this.props.location.search);
      window.ga('send', 'pageview');
    }
  }

  scrollTop() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      if (typeof window.ga === 'function') {
        window.ga('set', 'page', nextProps.location.pathname + nextProps.location.search);
        window.ga('send', 'pageview');
      }
    }
  }

  handleResize(e) {
    this.setState({ screen : { width: window.innerWidth, height: window.innerHeight }})
  }

  render() {

    return (
      <div className="app-inner">
        <Header screen={ this.state.screen }/>
        <TransitionGroup>
          <CSSTransition key={ "1" } classNames="page" timeout={{ enter: 1000, exit: 500 }} >
            <Switch location={ this.props.location }>
              <Route path="/" exact render={(props) => (<HomePage data={ this.props.data } screen={ this.state.screen } location={ this.props.location } />)}/>
              <Route path="/uslugi" exact render={(props) => (<HomePage data={ this.props.data } screen={ this.state.screen } location={ this.props.location } />)}/>
              <Route path="/oferta" exact render={(props) => (<HomePage data={ this.props.data } screen={ this.state.screen } location={ this.props.location } />)}/>
              <Route path="/finansowanie" exact render={(props) => (<HomePage data={ this.props.data } screen={ this.state.screen } location={ this.props.location } />)}/>
              <Route path="/kontakt" exact render={(props) => (<HomePage data={ this.props.data } screen={ this.state.screen } location={ this.props.location } />)}/>
              <Route render={(props) => (<ErrorPage data={ this.props.data }/>)} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </div>
    )
  }
}

export default withRouter(MainView)