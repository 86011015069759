import React from 'react'
import BaseComponent from './BaseComponent'
import Footer from './Footer'
import Scroll from 'react-scroll'
import Modal from './Modal'
import Parsley from 'parsleyjs'
import $ from 'jquery'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

let scroller = Scroll.scroller
let Element = Scroll.Element

export default class HomePage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      name: "",
      subject: "",
      email: "",
      message: "",
      messageAdditional: "",
      error: false, 
      popup: false,
      product: 0,
      showProduct: false,
      showCounters: false,
      played: false,
      checkbox: false,
      checkboxError: false
    }

    this._bind('sendForm', 'submitForm', 'handleInputChange', 'handleSelectChange', 'showError', 'showPopup', 'closeError', 'closePopup', 'scrollTo')

    Parsley.addMessages('pl', {
      defaultMessage: "Wartość wygląda na nieprawidłową",
      type: {
        email:        "Wpisz poprawny adres e-mail.",
        url:          "Wpisz poprawny adres URL.",
        number:       "Wpisz poprawną liczbę.",
        integer:      "Dozwolone są jedynie liczby całkowite.",
        digits:       "Dozwolone są jedynie cyfry.",
        alphanum:     "Dozwolone są jedynie znaki alfanumeryczne."
      },
      notblank:       "Pole nie może być puste.",
      required:       "Pole jest wymagane.",
      pattern:        "Pole zawiera nieprawidłową wartość.",
      min:            "Wartość nie może być mniejsza od %s.",
      max:            "Wartość nie może być większa od %s.",
      range:          "Wartość powinna zaweriać się pomiędzy %s a %s.",
      minlength:      "Minimalna ilość znaków wynosi %s.",
      maxlength:      "Maksymalna ilość znaków wynosi %s.",
      length:         "Ilość znaków wynosi od %s do %s.",
      mincheck:       "Wybierz minimalnie %s opcji.",
      maxcheck:       "Wybierz maksymalnie %s opcji.",
      check:          "Wybierz od %s do %s opcji.",
      equalto:        "Wartości nie są identyczne."
    });

    Parsley.setLocale('pl');
  }

  componentDidMount() {
    // Load the IFrame Player API code asynchronously.
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/player_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubePlayerAPIReady = () => {
      this.player = new window.YT.Player('ytplayer', {
        height: '100%',
        width: '100%',
        videoId: 'pjcWrWP0TMM',
        playerVars: {
          modestbranding: 1,
          playsinline: 1,
          rel: 0,
          showinfo: 0,
          origin: "https://bankomatgo.pl"
        },
        events: {
          'onStateChange': this.onPlayerStateChange.bind(this)
        }
      });
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  playVideo() {
    this.player.playVideo()
  }

  onPlayerStateChange(event) {
    if (event.data == window.YT.PlayerState.PLAYING) {
      this.setState({ played: true })
    } else {
      this.setState({ played: false })
    }
  }

  scrollTo(el) {
    let offset = 70

    if (this.props.screen.width > 1020) {
      offset = 80
    }

    if (this.props.screen.width > 1280) {
      offset = 95
    }

    if (this.props.screen.width > 1590) {
      offset = 115
    }

    if (this.props.screen.width > 1910) {
      offset = 155
    }

    scroller.scrollTo(el, {
      duration: 1200,
      delay: 100,
      smooth: "easeInOutQuad",
      offset: -offset
    })
  }

  handleInputChange(event) {
      const target = event.target
      let value = target.type === 'checkbox' ? target.checked : target.value
      let name = target.name

      this.setState({
        [name]: value
      })
  }

  handleSelectChange(event) {
      const target = event.target
      let value = target.type === 'checkbox' ? target.checked : target.value
      let name = target.name

      if (value === "iH 110Go" || value === "iH 110Go+" || value === "iH 110M" || value === "iH 110F") {
        this.setState({
          messageAdditional: "Jestem zainteresowany ofertą " + value + " - proszę o szczegóły/kontakt"
        })
      } else {
        this.setState({
          messageAdditional: ""
        })
      }

      this.setState({
        [name]: value
      })
  }

  showPopup() {
    this.setState({ popup: true })
  }

  showError() {
    this.setState({ error: true })
  }

  closePopup() {
    this.setState({ email: "", name: "", subject: "", message: "", error: false, popup: false })
  }

  closeError() {
    this.setState({ email: "", name: "", subject: "", message: "", error: false, popup: false })
  }

  sendForm(e) {
    e.preventDefault()

    if (!this.state.checkbox) {
      this.setState({ checkboxError: true })
    } 

    if($('.form').parsley().validate() && this.state.checkbox) {
      this.submitForm()
    }
  }

  submitForm() {

    let data = {
      name: this.state.name,
      subject: this.state.subject,
      email: this.state.email,
      message: this.state.message !== "" ? this.state.message : this.state.messageAdditional
    }

    fetch('/mailer/index.php', {
        method: "POST", 
        headers: {
            "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify(data),
    })
    .then((response, status) => {
      return response.json()
    })
    .then((result) => {
      if (result.result === 0) {
        this.showError()
      } else {
        this.showPopup()
        window.gtag('event', 'Formularz', {
        'event_category': 'formularz',
        'event_label': 'send',
        });
        window.gtag('event', 'conversion', {'send_to': 'AW-732274715/phHICJ_Z86IBEJvAlt0C'});
      }
    },(error) => {
      this.showError()
      console.log(error)
    })
  }

  render() {

    const singleProduct = this.props.data.products[this.state.product]

    const Popup = (
      <div className="popup-inner">
        <div className="close" onClick={ this.closePopup }></div>
        <img className="icon" src="/assets/img/ok.png" alt=""/>
        <h1>Dziękujemy</h1>
        <h4>Za zainteresowanie naszą ofertą</h4>
        <p>Skontaktujemy się z Państwem najszybciej jak to możliwe.</p>
        <button className="btn" onClick={ this.closePopup }><span>Zamknij</span><div className="close-modal"></div></button>
      </div>
    )

    const ErrorPopup = (
      <div className="popup-inner">
        <div className="close" onClick={ this.closeError }></div>
        <img className="icon fail" src="/assets/img/ok.png" alt=""/>
        <h1>Wystąpił błąd!</h1>
        <p>Prosimy spróbować ponownie.</p>
        <button className="btn" onClick={ this.closeError }><span>Zamknij</span><div className="close-modal"></div></button>
      </div>
    )

    const products = this.props.data.products.map((product, i) => {
      if (this.props.screen.width < 1020) {
        return (
          <li className="single-product" key={ i } style={{ transform: "translateX(-" + this.state.product * 130 + "%)" }}>
            <h2>
              <small>{ product.name }</small>
              <div dangerouslySetInnerHTML={{ __html: this.addColor(product.sub) }}></div>
            </h2>
            <div className="product-inner">
              <div className="image">
                <img src={ product.image } alt={ product.sub + " image" }/>
              </div>
              <ul className="list-right">
                { product.items.slice(0, 6).map((item, j) => {
                  return (
                    <li key={ j }>
                      <div className="icon">
                        <img src={ item.icon } alt="icon"/>
                      </div>
                      <div className="text">
                        <h4>{ item.name }</h4>
                        <p>{ item.description }</p>
                      </div>
                    </li>
                  )
                }) }
              </ul>
            </div>
            <div className="product-bottom">
              <div>
                <button className="btn" onClick={ () => { this.setState({ showProduct: true }); document.querySelector("body").style.overflow = "hidden" } }>Czytaj pełen opis<img src="/assets/img/arrow-green.png" alt="arrow"/></button>
              </div>
              <div className="price-holder">
                <p>{ product.priceText }</p>
                <span className="price" onClick={ this.scrollTo.bind(null, "pricing") }>{ product.price } ZŁ</span>
              </div>
            </div>
          </li>
        )
      } else {
        return (
          <li className="single-product" key={ i } style={{ transform: "translateX(-" + this.state.product * 130 + "%)" }}>
            <h2>
              <small>{ product.name }</small>
              <div dangerouslySetInnerHTML={{ __html: this.addColor(product.sub) }}></div>
            </h2>
            <div className="product-inner">
              <ul className="list-left">
                { product.items.slice(0, 3).map((item, j) => {
                  return (
                    <li key={ j }>
                      <div className="text">
                        <h4>{ item.name }</h4>
                        <p>{ item.description }</p>
                      </div>
                      <div className="icon">
                        <img src={ item.icon } alt="icon"/>
                      </div>
                    </li>
                  )
                }) }
              </ul>
              <div className="image">
                <img src={ product.image } alt={ product.sub + " image" }/>
              </div>
              <ul className="list-right">
                { product.items.slice(3, 6).map((item, j) => {
                  return (
                    <li key={ j }>
                      <div className="icon">
                        <img src={ item.icon } alt="icon"/>
                      </div>
                      <div className="text">
                        <h4>{ item.name }</h4>
                        <p>{ item.description }</p>
                      </div>
                    </li>
                  )
                }) }
              </ul>
            </div>
            <div className="product-bottom">
              <div>
                <button className="btn" onClick={ () => { this.setState({ showProduct: true }); document.querySelector("body").style.overflow = "hidden" } }>Czytaj pełen opis<img src="/assets/img/arrow-green.png" alt="arrow"/></button>
              </div>
              <div className="price-holder">
                <p>{ product.priceText }</p>
                <span className="price" onClick={ this.scrollTo.bind(null, "pricing") }>{ product.price } ZŁ</span>
              </div>
            </div>
          </li>
        )
      }
    }) 

    return (
      <section className="home">
        <Element name="head"/>
        <div className="top">
          <div className="container">
            <div className="top-inner">
              <div className="text">
                <h1><small>SKANER banknotów</small>iHunter<small className="smaller">tylko &nbsp;<span className="big">5</span> <span>zł</span> dziennie</small></h1>
                <p>w Ofercie BankomatGo płacisz tylko 5zł dziennie<br/>sam decydujesz przez ile dni korzystasz</p>
                <button className="btn" onClick={ this.scrollTo.bind(null, "contact") }>Zamów teraz<img src="/assets/img/arrow-green.png" alt="arrow"/></button>
              </div>
              <div className="img">
                <img src="/assets/img/ihunter-top.png" alt="iHunter"/>
              </div>
            </div>
            <img className="scroll" src="/assets/img/scroll.png" alt="scroll"/>
          </div>
        </div>
        <div className="products">
          <Element name="products"/>
          <div className="container">
            <ul className="products-list">
              <div className="prev" onClick={ () => { this.state.product > 0 ? this.setState({ product: this.state.product-1 }) : this.setState({ product: this.props.data.products.length-1 }) } } ></div>
              { products }
              <div className="next" onClick={ () => { this.state.product < this.props.data.products.length-1 ? this.setState({ product: this.state.product+1 }) : this.setState({ product: 0 }) } } ></div>
            </ul> 
          </div>
        </div>
        <div className="video">
          <div className="container">
            <div className="video-holder">
              <div id="ytplayer" className="vid"></div>
              { !this.state.played ? <div className="play-content" onClick={ () => { this.player.playVideo() } }>
                <h2>
                  <small>BankomatGo</small>
                  iH110
                </h2>
                <div className="play"></div>
              </div> : null }
            </div>
          </div>
        </div>
        <div className="offer">
          <span className="bg-text">iHunter</span>
          <div className="container">
            <p className="first-p">Obejrzyj w działaniu nowej generacji 2-kieszeniową liczarkę - sorter wyposażoną w technologię skanującą z firmy Hitachi o rozdzielczości 200dpi, która umożliwia weryfikację autentyczności banknotów 120 różnych walut, sczytywanie i sortowanie nominałów, sprawdzanie stopnia zużycia banknotów, uszkodzeń mechanicznych i podklejeń taśmą, przeglądanie na ekranie cech autentyczności, uszkodzeń banknotów wraz z wyliczeniem brakującej powierzchni, a także odczytywanie i  porównywanie numerów seryjnych banknotów i kodów kreskowych.</p>
            <div className="offer-inner">
              <div className="text">
                <Element name="offer"/>
                <h2 className="right"><small>Dostępne</small>Usługi</h2>
                <p>Jesteśmy specjalistami w zagadnieniach związanych z funkcjonowaniem gotówki na rynku. Od 25 lat pomagamy we wdrażaniu nowoczesnych technologii w obrocie pieniężnym w Polsce.</p>
                <p>Posiadamy wiedzę, kompetencje i cieszymy się zaufaniem wielu klientów. Decydując się teraz na wynajem lub zakup skanera iHunter wraz z produktem otrzymasz dostęp do pakietu gwarantowanych dodatkowych usług. Na nas możesz zawsze liczyć.</p>
              </div>
              <ul className="offer-items">
                <li>
                  <img src="/assets/img/offer-icon-1.png" alt="icon"/>
                  <h4>
                    GWARANCJA<br/>JAKOŚCI
                    <span>DOŻYWOTNIA</span>
                  </h4>
                  <p>przy wynajmie lub 36<br/>miesięcy przy zakupie </p>
                </li>
                <li>
                  <img src="/assets/img/offer-icon-2.png" alt="icon"/>
                  <h4>
                    BEZPŁATNA<br/>AKTUALIZACJA
                    <span>oprogramowania</span>
                  </h4>
                  <p>dostęp do plików<br/>elektronicznych</p>
                </li>
                <li>
                  <img src="/assets/img/offer-icon-3.png" alt="icon"/>
                  <h4>
                    WSPARCIE<br/>TECHNICZNE
                    <span>10 lat</span>
                  </h4>
                  <p>help-desk, up-grade,<br/>szkolenia</p>
                </li>
                <li>
                  <img src="/assets/img/offer-icon-4.png" alt="icon"/>
                  <h4>
                    usługi<br/>serwisowe BANKOMAT
                    <span>SERVICE HUB</span>
                  </h4>
                  <p>naprawy, konserwacje,<br/>części zamienne</p>
                </li>
                <li>
                  <img src="/assets/img/offer-icon-5.png" alt="icon"/>
                  <h4>
                    ZWROT PIENIĘDZY<br/>GDY ZREZYGNUJESZ
                    <span>Z ZAKUPU</span>
                  </h4>
                  <p>w wariancie BankomatGo<br/>iH110Go+</p>
                </li>
                <li>
                  <img src="/assets/img/offer-icon-6.png" alt="icon"/>
                  <h4>
                    GWARANCJA<br/>ZADOWOLONEGO
                    <span>KLIENTA</span>
                  </h4>
                  <p>dołącz do licznego grona<br/>użytkowników iHunterów</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="benefits">
          <div className="container">
            <h2><small>Odkryj</small>przewagę liczarek<small>wartościowych nad ilościowymi</small></h2>
            <button className="btn" onClick={ () => { this.setState({ showCounters: true }); disableBodyScroll(document.getElementById('counters')) } }>Sprawdź<img src="/assets/img/arrow-green.png" alt="arrow"/></button>
          </div>
        </div>
        <div className="financing">
          <Element name="financing"/>
          <div className="container">
            <h2>Finansowanie</h2>
            <ul className="financing-list">
              <li>
                <h4>Wynajem</h4>
                <p>Oferta BankomatGo obejmuje wynajem skanera iH-110 Go. Minimalny okres wypożyczenia wynosi 30 dni, maksymalny nie jest określony. Rozliczenie następuje w cyklach miesięcznych lub po zakończeniu wynajmu. Dzienna stawka za wynajem wynosi 5zł +VAT i obejmuje wyłącznie okres użytkowania. W trakcie obowiązywania umowy najmu naprawy wykonywane są nieodpłatnie w Bankomat Service Hub, a aktualizacje oprogramowania są udostępniane w postaci bezpłatnych elektronicznych plików. Liczba urządzeń dostępnych w ofercie jest ograniczona.</p>
              </li>
              <li>
                <h4>Gwarancja odkupu</h4>
                <p>Oferta BankomatGo obejmuje sprzedaż skanera iH-110 Go+ z gwarancją odkupu za cenę równą cenie sprzedaży, pomniejszoną o dzienną stawkę w wysokości 5zł +VAT. Bankomat odkupi liczarkę, po złożeniu odpowiedniej deklaracji przez użytkownika. Minimalny okres użytkowania wynosi 30 dni, maksymalny nie jest określony. W trakcie całego okresu użytkowania naprawy wykonywane są nieodpłatnie w Bankomat Service Hub, a aktualizacje oprogramowania są udostępniane w postaci bezpłatnych elektronicznych plików. Liczba urządzeń dostępnych w ofercie jest ograniczona.</p>
              </li>
              <li>
                <h4>Promocyjna sprzedaż</h4>
                <p>Oferta BankomatGo obejmuje sprzedaż skanera wielowalutowego iH-110M i sortera jakości obiegowej iH-110F po specjalnych promocyjnych cenach, z gwarancją wydłużoną do 36-miesięcy. W trakcie całego okresu użytkowania naprawy wykonywane są nieodpłatnie w Bankomat Service Hub, a aktualizacje oprogramowania są udostępniane w postaci bezpłatnych elektronicznych plików. Liczba urządzeń dostępnych w ofercie jest ograniczona. </p>
              </li>
            </ul>
            <Element name="pricing"/>
            <h2 className="right"><small>Porównanie wariantów </small>Oferty<span> BankomatGo</span></h2>
            <div className="table-holder">
              <table style={{ width: "100%", textAlign: "left" }} cellSpacing="0">
                <colgroup>
                  <col style={{ width: "25%" }}/>
                  <col style={{ width: "20%" }}/>
                  <col style={{ width: "20%" }}/>
                  <col style={{ width: "20%" }}/>
                  <col style={{ width: "15%" }}/>
                </colgroup>  
                <thead>
                  <tr>
                    <th>BankomatGo</th>
                    <th>iH-110 Go</th>
                    <th>iH-110 Go+</th>
                    <th>iH-110M</th>
                    <th>iH-110F</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Typ urządzenia</td>
                    <td>Skaner banknotów</td>
                    <td>Skaner banknotów</td>
                    <td>Skaner banknotów</td>
                    <td>Sorter banknotów</td>
                  </tr>
                  <tr>
                    <td>Finansowanie</td>
                    <td>Wynajem</td>
                    <td>Gwarancja odkupu</td>
                    <td>Promocyjna sprzedaż</td>
                    <td>Promocyjna sprzedaż</td>
                  </tr>
                  <tr>
                    <td>Gwarancja</td>
                    <td>Bezterminowa</td>
                    <td>Do dnia odkupu</td>
                    <td>36 miesięcy</td>
                    <td>36 miesięcy</td>
                  </tr>
                  <tr>
                    <td>Konfiguracja</td>
                    <td>PLN</td>
                    <td>PLN</td>
                    <td>PLN ,EUR, USD, GBP, CHF, NOK, SEK, DKK, CZK, HUF</td>
                    <td>PLN</td>
                  </tr>
                  <tr>
                    <td>Zgodność z Zarządzeniem Prezesa NBP Nr 19/2016</td>
                    <td>Tak</td>
                    <td>Tak</td>
                    <td>Tak</td>
                    <td>Tak</td>
                  </tr>
                  <tr>
                    <td>Sortowanie jakości obiegowej</td>
                    <td>Nie</td>
                    <td>Nie</td>
                    <td>Nie</td>
                    <td>Tak</td>
                  </tr>
                  <tr>
                    <td>Dodatkowe waluty</td>
                    <td>Nie</td>
                    <td>Tak</td>
                    <td>Tak</td>
                    <td>Nie</td>
                  </tr>
                  <tr>
                    <td>Dodatkowe oprogramowanie</td>
                    <td>Nie</td>
                    <td>Tak</td>
                    <td>Tak</td>
                    <td>Nie</td>
                  </tr>
                  <tr>
                    <td>Koszt (netto bez VAT)</td>
                    <td>5 zł dziennie</td>
                    <td>5475 zł</td>
                    <td>5995 zł</td>
                    <td>7995 zł</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="contact">
          <Element name="contact"/>
          <div className="container">
            <h2><span>Kontakt</span></h2>
            <p>Od 25 lat pomagamy we wdrażaniu nowoczesnych technologii związanych z obrotem pieniężnym w Polsce. Jesteśmy członkiem Polskiej Organizacji Firm Obsługi Gotówki POFOG zrzeszającej firmy działające w branży. Posiadamy wiedzę, kompetencje i cieszymy się zaufaniem wielu klientów. Na nas możesz zawsze liczyć.</p>
            <p><strong>Jesteś zainteresowany naszą ofertą? Wypełnij formularz.</strong></p>
          </div>
          <form className="form">
            <div className="container">
              <div className="form-inner">
                <div className="inputs">
                  <input type="text" name="name" value={ this.state.name } onChange={ this.handleInputChange } placeholder="Imię i nazwisko" required/>
                  <input type="email" name="email" value={ this.state.email } onChange={ this.handleInputChange } placeholder="Adres e-mail" required/>
                  <select className="last" value={ this.state.subject } name="subject" onChange={ this.handleSelectChange } required>
                    <option value="">Temat wiadomości</option>
                    <option value="iH 110Go">iH 110Go</option>
                    <option value="iH 110Go+">iH 110Go+</option>
                    <option value="iH 110M">iH 110M</option>
                    <option value="iH 110F">iH 110F</option>
                    <option value="inne">Inne</option>
                  </select>
                </div>
                <textarea name="message" value={ this.state.message !== "" ? this.state.message : this.state.messageAdditional } onChange={ this.handleInputChange } placeholder="Treść wiadomości" required></textarea>
              </div>
              <div className="checkboxes">
                <input type="checkbox" name="checkbox" id="rodo1" onChange={ this.handleInputChange} checked={ this.state.checkbox ? true : false }/>
                <label htmlFor="rodo1"><span>*</span> Wyrażam zgodę na przetwarzanie moich danych osobowych przez Bankomat Sp. z o.o. sp.k. w celach niepublicznego kontaktu z obsługą klienta.</label>
                { this.state.checkboxError && !this.state.checkbox ? <p className="checkbox-error">Zgoda wymagana</p> : null }
              </div>
              <div className="btn-holder">
                <button className="btn" onClick={ this.sendForm }>Wyślij wiadomość<img src="/assets/img/arrow-green.png" alt="arrow"/></button>
              </div>
            </div>
          </form>
        </div>
        <Footer/>
        <Modal open={ this.state.popup } hidePopup={ this.closePopup }>{ Popup }</Modal>
        <Modal open={ this.state.error } hidePopup={ this.closeError }>{ ErrorPopup }</Modal>
        <div className={ this.state.showProduct ? "product-modal show" : "product-modal" } id="product">
          <div className="close" onClick={ () => { this.setState({ showProduct: false }); document.querySelector("body").style.overflow = "initial" } }></div>
          <div className="product-popup-top">
            <div className="container">
              <div className="text">
                <h2>
                  <small>{ singleProduct.name }</small>
                  <div dangerouslySetInnerHTML={{ __html: this.addColor(singleProduct.sub) }}></div>
                </h2>
                <p className="description">{ singleProduct.description }</p>
                <ul className="manual">
                  { singleProduct.manual.map((item, i) => {
                    return (
                      <li key={ i }>
                        { item }
                      </li>
                    )
                  }) }
                </ul>
              </div>
              <div className="product-image">
                <img src={ singleProduct.imageMan } alt={ singleProduct.name + " " + singleProduct.sub }/>
              </div>
            </div>
          </div>
          <div className="product-popup-data">
            <div className="container">
              <ul className="list">
                <h4>Charakterystyka ogólna</h4>
                { singleProduct.data.map((item, i) => {
                  return (
                    <li key={ i }> 
                      { item }
                    </li>
                  )
                }) }
              </ul>
              <ul className="list">
                <h4>Tryby pracy</h4>
                { singleProduct.work.map((item, i) => {
                  return (
                    <li key={ i }> 
                      { item }
                    </li>
                  )
                }) }
              </ul>
            </div>
          </div>
          <div className="product-popup-det-comm">
            <div className="container">
              <ul className="list">
                <h4>Pakiet detektorów Hitachi</h4>
                { singleProduct.detectors.map((item, i) => {
                  return (
                    <li key={ i }> 
                      { item }
                    </li>
                  )
                }) }
              </ul>
              <ul className="list">
                <h4>Komunikacja i opcjonalne wyposażenie</h4>
                { singleProduct.communication.map((item, i) => {
                  return (
                    <li key={ i }> 
                      { item }
                    </li>
                  )
                }) }
              </ul>
            </div>
          </div>
          <div className="product-popup-benefits">
            <div className="container">
              <ul className="items">
                { singleProduct.items.map((item, j) => {
                  return (
                    <li key={ j }>
                      <div className="icon">
                        <img src={ item.icon } alt="icon"/>
                      </div>
                      <div className="text">
                        <h4>{ item.name }</h4>
                        <p>{ item.description }</p>
                      </div>
                    </li>
                  )
                }) }
              </ul>
            </div>
          </div>
          <div className="product-popup-bottom">
            <div className="container">
              <div className="back-to-top" onClick={ () => { document.getElementById('product').scrollTop = 0 } }></div>
              <div className="buttons">
                <button className="btn" onClick={ () => { this.scrollTo("contact"); this.setState({ showProduct: false }); document.querySelector("body").style.overflow = "initial" } }>Gdzie kupić?<img src="/assets/img/arrow-green.png" alt="arrow"/></button>
                <a href="/assets/SPECYFIKACJA_1.pdf" target="_blank" className="btn">Karta produktu<img src="/assets/img/arrow-green.png" alt="arrow"/></a>
              </div>
              <div className="price" onClick={ () => { this.scrollTo("pricing"); this.setState({ showProduct: false }); document.querySelector("body").style.overflow = "initial" } }>
                { singleProduct.price + " ZŁ" }
              </div>
            </div>
          </div>
        </div>
        <div className={ this.state.showCounters ? "counters-modal show" : "counters-modal" } id="counters">
          <div className="close" onClick={ () => { this.setState({ showCounters: false }); enableBodyScroll(document.getElementById('counters')) } }></div>
          <div className="container">
            <h2>
              <small>Odkryj przewagę</small>
              Liczarek
              <small><span>wartościowych</span> nad ilościowymi</small>
            </h2>
            <div className="table-holder">
              <table style={{ width: "100%", textAlign: "left" }} cellSpacing="0">
                <colgroup>
                  <col style={{ width: "20%" }}/>
                  <col style={{ width: "30%" }}/>
                  <col style={{ width: "30%" }}/>
                  <col style={{ width: "10%" }}/>
                </colgroup> 
                <thead>
                  <tr>
                    <th>funkcje:</th>
                    <th>Liczarki ilościowe</th>
                    <th>Liczarki wartościowe</th>
                    <th>Korzyść</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Prędkość<br/>liczenia</td>
                    <td>Niska, powtórne przeliczenie po każdym błędzie lub zacięciu</td>
                    <td>Liczenie bez zatrzymywania, banknoty nierozpoznane odrzucane do drugiej kieszeni</td>
                    <td>Czas</td>
                  </tr>
                  <tr>
                    <td>Liczenie zmieszanych banknotów</td>
                    <td>Niemożliwe</td>
                    <td>Tak, z zapisem struktury ilościowo-wartościowej </td>
                    <td>Czas</td>
                  </tr>
                  <tr>
                    <td>Rozdzielanie<br/>nominałów</td>
                    <td>Ręczne, wymagane przed liczeniem</td>
                    <td>Niewymagane do przeliczenia</td>
                    <td>Czas</td>
                  </tr>
                  <tr>
                    <td>Porządkowanie banknotów<br/>awersem i dolną krawędzią</td>
                    <td>Ręczne, wymagane przed liczeniem</td>
                    <td>Niewymagane do przeliczenia</td>
                    <td>Czas</td>
                  </tr>
                  <tr>
                    <td>Raport<br/>z przeliczenia</td>
                    <td>Ręcznie, osobno dla każdego nominału</td>
                    <td>Wydruk lub przesł danych zawierających strukturę przeliczenia, z sygnaturą daty i czasu</td>
                    <td>Czas</td>
                  </tr>
                  <tr>
                    <td>Sumowanie wartości<br/>przeliczonych banknotów</td>
                    <td>Ręcznie, osobno dla każdego nominału</td>
                    <td>Bieżące sumowanie, wynik dostępny natychmiast po zakończeniu przeliczenia</td>
                    <td>Czas</td>
                  </tr>
                  <tr>
                    <td>Liczenie<br/>walut</td>
                    <td>Ilościowe</td>
                    <td>Automatyczne rozpoznawanie waluty i jednoczesne zliczanie banknotów 16 walut</td>
                    <td>Czas</td>
                  </tr>
                  <tr>
                    <td>Sortowanie<br/>jakościowe</td>
                    <td>Ręcznie</td>
                    <td>Razem z przeliczeniem, zgodnie z wymaganiami NBP </td>
                    <td>Czas</td>
                  </tr>
                  <tr>
                    <td>Sprawdzanie<br/>autentyczności</td>
                    <td>Ręczne, zwykle 1 lub 2-ch zabezpieczeń</td>
                    <td>Jednoczesne wszystkich zabezpieczeń, wraz z przeliczeniem</td>
                    <td>Czas i bezpieczeństwo</td>
                  </tr>
                  <tr>
                    <td>Tester<br/>banknotów</td>
                    <td>Nie</td>
                    <td>Filtrowanie i przeglądanie zabezpieczeń na ekranie LCD</td>
                    <td>Bezpieczeństwo</td>
                  </tr>
                  <tr>
                    <td>‘czarna lista’<br/>numer banknotów</td>
                    <td>Nie</td>
                    <td>Automatycznie</td>
                    <td>Bezpieczeństwo</td>
                  </tr>
                  <tr>
                    <td>Interfejs<br/>użytkownika</td>
                    <td>Klawiatura i wyświetlacz LED</td>
                    <td>Dotykowy wyświetlacz LCD</td>
                    <td>Komfort</td>
                  </tr>
                  <tr>
                    <td>Integracja z innymi<br/>urządzeniami</td>
                    <td>Nie</td>
                    <td>Tak</td>
                    <td>Komfort</td>
                  </tr>
                  <tr>
                    <td>Współpraca z system<br/>informatycznym</td>
                    <td>Nie</td>
                    <td>Tak</td>
                    <td>Komfort</td>
                  </tr>
                  <tr>
                    <td>Sczytywanie<br/>numerów seryjnych</td>
                    <td>Nie</td>
                    <td>Automatyczne</td>
                    <td>Nowa funkcja</td>
                  </tr>
                  <tr>
                    <td>Sczytywanie kodów<br/>kreskowych</td>
                    <td>Nie</td>
                    <td>Automatyczne</td>
                    <td>Nowa funkcja</td>
                  </tr>
                  <tr>
                    <td>Rozdzielanie<br/>emisji</td>
                    <td>Nie</td>
                    <td>Automatyczne</td>
                    <td>Nowa funkcja</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="back-to-top" onClick={ () => { document.getElementById('counters').scrollTop = 0 } }></div>
          </div>
        </div>
      </section>
    )
  }
}