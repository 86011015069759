import React from 'react'
import BaseComponent from './BaseComponent'
import { NavLink } from 'react-router-dom'
import Footer from './Footer'

export default class extends BaseComponent {

  render () {

    return (
      <section className="error-page">
        <div className="container">
          <div className="content">
            <NavLink to="/"><img className="logo" src="/assets/img/logo.png" alt="Logo"/></NavLink>
      			<h1>404</h1>
    				<h3 className="error">Strona nie istnieje!</h3>
    				<NavLink to="/"><span>&lt;&nbsp;&nbsp;</span>Wróć do strony głównej</NavLink>
          </div>
        </div>
        <Footer/>
      </section>
    )
  }
}
